import React from 'react';
import styles from '../style';
import {discount, robot, crowdfunding} from '../assets';
import {GetStarted} from './index';
import { useRef } from "react";
import { motion } from "framer-motion";
const Hero = () => {
    const constraintsRef = useRef(null);
  return (
    <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY}`}>
        <div className={`flex-1 flex justify-start items-start flex-col xl:px-0 sm:px-16 px-6`}>


            <div className="flex flex-row justify-between items-center w-full">
                <h1 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100px] leading-[75px]">
                    Where Money <br className="sm:block hidden"/> {" "}
                    <span className="text-gradient">Grows</span> {" "}

                </h1>


            </div>
            <h1 className="font-poppins font-semibold ss:text-[68px] text-[52px] text-white ss:leading-[100px] leading-[75px] w-full"> For
                You.</h1>
            <p style={{color: 'white'}} className={`${styles.paragraph} max-w-[470px] mt-5`}>
                Money should not just sit in your bank account.
                Why don't we use it together to make something?
            </p>
        </div>


        <div className={`flex-1 flex ${styles.flexCenter} md:mr-0  my-10 relative`}>
            <img src={crowdfunding} className="w-[50%] " alt="" srcset="" />
        <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient"/>
        <div className="absolute z-[1] w-[80%] h-[80%] rounded-full bottom-40 white__gradient"/>
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient"/>
      </div>

        <div className={`ss:hidden ${styles.flexCenter}`}>
            <motion.div className="framer-container" ref={constraintsRef}>
                <motion.div className="item" drag dragConstraints={constraintsRef} initial={{scale: 1}}
                            whileHover={{scale: 1.2, rotate: 360}}
                            whileTap={{scale: 0.8, rotate: 360}}
                            animate={{scale: 1.5}}
                            transition={{duration: 0.5}}
                >
                    <GetStarted/>
                </motion.div>
            </motion.div>
        </div>
    </section>
  )
}

export default Hero